<i18n>
{
    "uk": {
        "register": "Створити профіль",
        "login": "Увійти",
        "hero": {
            "title": "Cоцмережа та&nbsp;EdTech для&nbsp;креативних індустрій",
            "headline": "Дизайн, маркетинг, реклама, брендинг, стартапи, IT і не тільки",
            "scroll-hint": "гортайте, щоб дізнатись більше",
            "awards": {
                "grants": "Гранти",
                "participant": "Участь",
                "winner": "Переможці"
            }
        },

        "about": {
            "title": "Про нас",
            "text": "{name} — це соцмережа креативних та ІТ-індустрій та освітня платформа, створена в Україні. Тут ви можете навчатись та викладати, створювати портфоліо та вести блог, ділитись новинами та дізнаватись нове, знаходити замовників та фахівців."
        },

        "features": {
            "title": "Можливості платформи",
            "text": "З нами ви можете навчатись або викладати, розвивати власне портфоліо та вести свій блог, ділитися новинами та відкривати для себе нові ідеї, а також знаходити партнерів для співпраці.",
            "study": {
                "title": "Навчайтесь та викладайте",
                "text": "Ми створили власну потужну освітню платформу — Креативну Практику. Вона повністю інтегрована в нашу соцмережу, і завдяки цьому, тут можна пройти шлях від знайомства з професією до початку чи розвитку кар’єри. А якщо ви маєте достатньо досвіду, ми завжди раді створювати спільні освітні продукти!",
                "link": "Отримати 7 днів безкоштовного навчання"
            },
            "feed": {
                "title": "Онлайн-медіа з персоналізованою стрічкою",
                "text": "Читайте ті новини, кейси, блоги, які цікавлять саме вас!",
                "link": "Зареєструватись й оцінити"
            },
            "cases": {
                "title": "Потужна портфоліо-платформа",
                "text": "Ви можете створити власне портфоліо та публікувати кейси з будь-якої галузі! Будь то дизайн, SEO, маркетинг, реклама, розробка програмного забезпечення або підприємництво.",
                "link": "Зареєструйтесь і створіть портфоліо"
            },
            "editor": {
                "title": "Дійсно зручний редактор!",
                "text": "У нашому редакторі можна створити кейси, статті та новини всього за кілька хвилин! Крім того, у нас є функція автоматичного збереження матеріалів та можливість спільного редагування.",
                "link": "Зареєструйтесь і додайте свій кейс"
            },
            "jobs": {
                "title": "Шукайте роботу та публікуйте ваші вакансії на CASES",
                "text": "Сотні працедавців вже шукають талановитих фахівців разом з нами! Ми пропонуємо наскрізний інтерфейс взаємодії працедавця з кандидатами.",
                "link": "До біржі вакансій"
            }
        },

        "talents": {
            "title": "Знаходьте таланти та партнерів",
            "text": "Наша платформа об’єднує тисячі талановитих фахівців — початківців та досвідчених. Ми завжди раді допомогти вам у пошуку дизайнерів, розробників, копірайтерів, маркетологів і не тільки!"
        },

        "companies": {
            "headline": "Понад 1500 компаній вже{nl} мають профіль на CASES",
            "create": "Додати компанію"
        },

        "reviews": {
            "title": "Відгуки про CASES",
            "item1": {
                "name": "Михайло Самоваров",
                "position": "Дизайн-директор League Design Agency",
                "text": "На мою думку, рецепт успіху проєкту потребує розуміння проблем ринку, готовність братись за їхнє вирішення, методична та цілеспрямована робота, уміння сприймати реальність та щире піклування про людей. А ще секретний інгредієнт — фантазія та творчий підхід. Команда CASES вже не першій рік доводить що має все це."
            },
            "item2": {
                "name": "Роман Геращенко",
                "position": "CEO агенції Sasquatch",
                "text": "В CASES ми закохалися з першого погляду! Тут зручно публікувати як короткі релізи, так і великі аналітичні матеріали. Команда платформи завжди іде на зустріч. Цей майданчик за пів кроку від того, щоб стати корисним інструментом для комунікаційників, і вже зараз — обов’язковий запис у збережених посиланнях."
            },
            "item3": {
                "name": "Максим Якубович",
                "position": "CEO Goodface",
                "text": "CASES – моя улюблена професійна соцмережа. Тут багато цікавого контента, потужна спільнота, зручний та приємний інтерфейс, в якому як ніде зручно вести портфоліо. На додачу до цього платформа дає купу можливостей для просування компанії і приводить додатковий трафік до нас на сайт. Тому ми регулярно публікуємо тут свої матеріали та шукаємо партнерів."
            },
            "item4": {
                "name": "Ольга Шевченко",
                "position": "Креативна директорка Vintage",
                "text": "CASES — це унікальна платформа, що поєднує в собі UGC-медіа з цікавим контентом та онлайн-освіту з якісними навчальних матеріалів. Це особливо корисно для новачків та тих, хто хоче розвивати свої навички та знання в галузях, пов’язаних з креативними індустріями. А ще це класний український продукт, який створює команда досвічених професіоналів."
            },
            "item5": {
                "name": "Юлія Кравчук",
                "position": "Менеджерка бренду працедавця у&nbsp;Reface",
                "text": "Як спеціаліст з комунікацій, я щаслива працювати з CASES як майданчиком для підвищення обізнаності про бренд та пошуку талантів. Вражає той факт, що українська команда, в умовах війни з росією, розробляє цю соціальну мережу та освітню платформу, спеціально адаптовану для творчих та ІТ-індустрій, демонструючи свою пристрасть до створення вишуканого продукту."
            },
            "item6": {
                "name": "Артем Беседа",
                "position": "СЕО Rocketman",
                "text": "Я вважаю що CASES суттєво вплинули на українську креативну індустрію. Ми завжди отримували підтримку від платформи, цінні контакти та таланти. Окремо відзначу благодійні ініціативи, такі як Марафон Креативної Практики. Це теж свідчить на крутезну філософію Кейсес. З їхнім підходом до справи, вважаю, у CASES є всі шанси стати українським креативним «яблуком»!"
            }
        },

        "join": {
            "title": "Долучайтесь!",
            "user": {
                "title": "Долучитись до спільноти як",
                "titleAction": "фахівець",
                "text1": "Створіть профіль на платформі!",
                "text2": "Це займе лічені хвилини.",
                "link": "Зареєструватись як фахівець"
            },
            "company": {
                "title": "Долучитись до спільноти як",
                "titleAction": "організація",
                "text": "Створіть профіль компанії, щоб ділитись вашими новинами, вести блог та портфоліо.",
                "link": "Додати компанію"
            }
        },

        "faq": {
            "title": "Часто задавані запитання",
            "pair1": {
                "q": "Скільки коштує створення профілю?",
                "a": "Створити власний профіль чи профіль компанії на платформі можна безкоштовно."
            },
            "pair2": {
                "q": "Скільки коштує публікація кейсу, статті чи новини?",
                "a": "Розміщення кейсів, статей та новин на платформі є безкоштовним."
            },
            "pair3": {
                "q": "Чи можна разом з вами створювати освітні матеріали?",
                "a": "Звісно! Ми будемо цьому щиро раді."
            },
            "pair4": {
                "q": "Чи можна оплачувати ваші послуги інвойсом?",
                "a": "Так."
            },
            "pair5": {
                "q": "Яку кількість переглядів отримають наші матеріали?",
                "a": "Це залежить від багатьох чинників: наприклад, від якості ваших матеріалів, їх пропрацьованноості, кількості підписників у вашого профілю."
            },
            "pair6": {
                "q": "Чи мені можуть допомогти з супроводом сторінки?",
                "a": "Так, ми залюбки з цим допоможемо."
            },
            "pair7": {
                "q": "Хто тримає цей район?",
                "a": "Пес Патрон."
            }
        }
    },
    "en": {
        "register": "Create  Profile",
        "login": "Log In",
        "hero": {
            "title": "Social Media and&nbsp;EdTech for&nbsp;Creative Industries",
            "headline": "Design, marketing, advertising, branding, startups, IT, and more",
            "scroll-hint": "Scroll to learn more",
            "awards": {
                "grants": "Grants",
                "participant": "Participation",
                "winner": "Winners"
            }
        },

        "about": {
            "title": "About Us",
            "text": "{name} is a social network and educational platform for the creative and IT industries, created in Ukraine. Here you can learn and teach, create a portfolio and blog, share news and learn something new, find clients and experts."
        },

        "features": {
            "title": "Opportunities of the Platform",
            "text": "With us, you can learn or teach, develop your own portfolio and run your blog, share news and discover new ideas, as well as find partners for collaboration.",
            "study": {
                "title": "Learn and Teach",
                "text": "We have created our own powerful educational platform - Creative Practice. It is fully integrated into our social network, which allows you to go from getting to know the profession to starting or developing your career. And if you have enough experience, we are always happy to create joint educational products!",
                "link": "Get 7 days of free learning"
            },
            "feed": {
                "title": "Online Media With a Personalized Feed",
                "text": "Read the news, cases, and blogs that interest you specifically!",
                "link": "Register and try"
            },
            "cases": {
                "title": "Powerful Portfolio Platform",
                "text": "You can create your own portfolio and publish case studies in any field! Whether it's design, SEO, marketing, advertising, software development, or entrepreneurship.",
                "link": "Register and create portfolio"
            },
            "editor": {
                "title": "A Really Convenient Editor!",
                "text": "In our editor, you can create cases, articles, and news in just a few minutes! In addition, we have an automatic save function for materials and the ability to collaborate on editing.",
                "link": "Register and add your case"
            },
            "jobs": {
                "title": "Find a Job and Post Your Vacancies on CASES",
                "text": "Hundreds of employers are already searching for talented professionals with us! We offer a seamless interface for employer-candidate interaction.",
                "link": "To the jobs list"
            }
        },

        "talents": {
            "title": "Find Talents and Partners",
            "text": "Our platform unites thousands of talented professionals, both beginners and experienced. We are always happy to help you find designers, developers, copywriters, marketers, and more!"
        },

        "companies": {
            "headline": "Over 1500 companies already{nl} have a profile on CASES.",
            "create": "Add company"
        },

        "reviews": {
            "title": "Feedback About CASES",
            "item1": {
                "name": "Mike Samovarov",
                "position": "Design director of League Design Agency",
                "text": "I believe that the key to a successful project lies in understanding the market’s problems, being ready to tackle them, working methodically and purposefully, having a realistic outlook, and genuinely caring about people. And let’s not forget the secret ingredient – imagination and a creative approach. The CASES team has already shown for several years that they possess all of these qualities."
            },
            "item2": {
                "name": "Roman Gerashchenko",
                "position": "CEO Sasquatch Agency",
                "text": "We were smitten with CASES from the get-go! It’s a breeze to publish both short releases and lengthy analytical pieces on the platform. The team behind it is always willing to help. This platform is poised to become an indispensable tool for communicators, and it’s already a go-to bookmark for us."
            },
            "item3": {
                "name": "Maksym Yakubovych",
                "position": "CEO Goodface",
                "text": "CASES is my favorite professional social network. It has a lot of interesting content, a powerful community, and a user-friendly interface that makes it easier than anywhere else to maintain a portfolio. Additionally, the platform provides a lot of opportunities to promote the company and drives additional traffic to our website. Thus, we regularly publish our materials here and look for partners."
            },
            "item4": {
                "name": "Olga Shevchenko",
                "position": "Creative Director at Vintage",
                "text": "CASES is a one-of-a-kind platform that seamlessly integrates UGC media with captivating content and top-notch online education, featuring high-quality learning materials. It's especially advantageous for newcomers and individuals looking to enhance their skills and knowledge in creative industries. Moreover, it's a fantastic Ukrainian product crafted by a team of seasoned professionals."
            },
            "item5": {
                "name": "Julia Kravchuk",
                "position": "Employer Brand Manager at&nbsp;Reface",
                "text": "As a communications professional, I'm delighted to work with CASES as an additional channel for brand awareness and talent search. It's impressive that the Ukrainian team, amidst the russian war in Ukraine, is developing this social network and educational platform tailored for the creative and IT industries, showcasing their dedication to creating a sophisticated product."
            },
            "item6": {
                "name": "Artem Beseda",
                "position": "СЕО Rocketman",
                "text": "I truly believe that CASES has made a substantial impact on the Ukrainian creative industry. We've consistently received support, valuable connections, and access to talented individuals through the platform. I must give a special shout-out to their charitable initiatives, like the Creative Practice Marathon. It truly embodies the greatness of CASES' philosophy. With their dedicated approach, I firmly believe that CASES has every chance of becoming the Ukrainian creative «trailblazer»!"
            }
        },

        "join": {
            "title": "Join In!",
            "user": {
                "title": "Join the community as an",
                "titleAction": "expert",
                "text1": "Create a profile on the platform!",
                "text2": "It will take a few minutes.",
                "link": "Register as a specialist"
            },
            "company": {
                "title": "Join the community as a",
                "titleAction": "company",
                "text": "Create a company profile to share your news, maintain a blog, and showcase your portfolio.",
                "link": "Add a company"
            }
        },

        "faq": {
            "title": "Frequently Asked Questions",
            "pair1": {
                "q": "How much does it cost to create a profile?",
                "a": "Creating a personal or company profile on the platform is free of charge."
            },
            "pair2": {
                "q": "How much does it cost to publish a case, article, or news?",
                "a": "Posting cases, articles, and news on the platform is free of charge."
            },
            "pair3": {
                "q": "Can we create educational materials together with you?",
                "a": "Of course! We would be happy to collaborate on educational materials."
            },
            "pair4": {
                "q": "Can we pay for your services via invoice?",
                "a": "Yes."
            },
            "pair5": {
                "q": "How many views will our materials get?",
                "a": "This depends on various factors, such as the quality of your materials, how well they are crafted, and the number of subscribers to your profile."
            },
            "pair6": {
                "q": "Can you assist me with managing my page?",
                "a": "Yes, we would be happy to assist you with this."
            },
            "pair7": {
                "q": "Who runs this area?",
                "a": "Dog Patron."
            }
        }
    }
}
</i18n>


<template>
    <div class="pb-6">

        <section id="hero" class="block-hero mt-10 mt-lg-0">
            <div class="page-container">

                <div class="hero-main row align-items-center">
                    <div class="col-12 col-md-10 col-lg-6 col-xl-5 offset-md-1 offset-lg-0 pt-lg-1 ta-center ta-lg-left transition-up-1">

                        <div class="hero-stencil-logo">
                            <div class="scheme-light-only">
                                <svg width="322" height="62" viewBox="0 0 322 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M322 0H0V62H322V0ZM264.941 30.9986C264.941 16.0653 277.21 3.93197 292.311 3.93197C307.6 3.93197 319.87 16.0653 319.87 30.9986H310.809C310.809 45.932 298.351 58.0653 283.251 58.0653C268.15 58.0653 255.881 45.932 255.881 30.9986H264.941ZM217.936 3L246.061 30.6267L217.936 58.44L190 30.6267L217.936 3ZM48.8118 11.9586L29.3698 30.9986L48.8118 50.0386C43.9041 55.0786 36.9201 58.0653 29.3698 58.0653C14.2692 58.0653 2 45.932 2 30.9986C2 16.0653 14.2692 3.93197 29.3698 3.93197C36.9201 3.93197 43.9041 7.1053 48.8118 11.9586ZM112.044 56.0125H57.1152L84.6738 7.47915L112.044 56.0125ZM125.07 30.9986C125.07 16.0653 137.339 3.93197 152.44 3.93197C167.541 3.93197 179.81 16.0653 179.81 30.9986H170.749C170.749 45.932 158.48 58.0653 143.38 58.0653C128.279 58.0653 116.01 45.932 116.01 30.9986H125.07Z" fill="white"/>
                                </svg>
                            </div>
                            <div class="scheme-dark-only">
                                <svg width="322" height="62" viewBox="0 0 322 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M322 0H0V62H322V0ZM264.941 30.9986C264.941 16.0653 277.21 3.93197 292.311 3.93197C307.6 3.93197 319.87 16.0653 319.87 30.9986H310.809C310.809 45.932 298.351 58.0653 283.251 58.0653C268.15 58.0653 255.881 45.932 255.881 30.9986H264.941ZM217.936 3L246.061 30.6267L217.936 58.44L190 30.6267L217.936 3ZM48.8118 11.9586L29.3698 30.9986L48.8118 50.0386C43.9041 55.0786 36.9201 58.0653 29.3698 58.0653C14.2692 58.0653 2 45.932 2 30.9986C2 16.0653 14.2692 3.93197 29.3698 3.93197C36.9201 3.93197 43.9041 7.1053 48.8118 11.9586ZM112.044 56.0125H57.1152L84.6738 7.47915L112.044 56.0125ZM125.07 30.9986C125.07 16.0653 137.339 3.93197 152.44 3.93197C167.541 3.93197 179.81 16.0653 179.81 30.9986H170.749C170.749 45.932 158.48 58.0653 143.38 58.0653C128.279 58.0653 116.01 45.932 116.01 30.9986H125.07Z" fill="#1f2022"/>
                                </svg>
                            </div>
                        </div>

                        <h1 class="h3 h2-md h1-lg mt-3 mb-2 fw-800 fw-md-700 mr-lg-n9" v-html="$t('hero.title')"></h1>

                        <div class="hero-subtitle h3-md mt-2 mb-3 fw-md-400">
                            <p>{{ $t('hero.headline') }}</p>
                        </div>

                        <div class="mt-3 mr-lg-n4">
                            <div class="hero-actions m-n1 ta-center d-inline-flex flex-column flex-md-row">
                                <nuxt-link
                                    :to="localePath('/registration')"
                                    class="btn-primary m-1"
                                >{{ $t('register') }}</nuxt-link>


                                <button
                                    @click="showLoginDialog()"
                                    class="action-video btn-secondary fw-600 tt-upper d-flex justify-content-center m-1 order-md-first"
                                >
                                    <span>{{ $t('login') }}</span>
                                </button>
                            </div>
                        </div>

                        <div class="hero-scroll d-none d-lg-flex flex-nowrap mt-7 color-gray-0">
                            <span class="icon-anim-scroll mr-1" />
                            <span class="fw-700">{{ $t('hero.scroll-hint') }}</span>
                        </div>

                    </div>

                    <div class="hero-image d-none d-lg-block col-lg-6 col-xl-7 transition-up-2">
                        <div class="scheme-light-only">
                            <WebpPicture
                                class="bg"
                                src="https://cdn.cases.media/image/large/cases-landing-hero-background.png"
                                width="630"
                                height="536"
                            />
                            <WebpPicture
                                class="fg fg-nocard"
                                :src="`https://cdn.cases.media/image/original/cases-landing-hero-image-tablet-${locale}.png`"
                                width="666"
                                height="425"
                            />
                            <WebpPicture
                                class="fg fg-card"
                                :src="`https://cdn.cases.media/image/original/cases-landing-hero-image-${locale}.png`"
                                width="666"
                                height="540"
                            />
                        </div>
                        <div class="scheme-dark-only">
                            <WebpPicture
                                class="bg-glow"
                                src="https://cdn.cases.media/image/large/cases-landing-hero-dark-gradient.png"
                                width="810"
                                height="764"
                            />
                            <WebpPicture
                                class="bg"
                                src="https://cdn.cases.media/image/large/cases-landing-hero-background.png"
                                width="630"
                                height="536"
                            />
                            <WebpPicture
                                class="fg fg-nocard"
                                :src="`https://cdn.cases.media/image/original/cases-landing-hero-dark-image-tablet-${locale}.png`"
                                width="666"
                                height="425"
                            />
                            <WebpPicture
                                class="fg fg-card"
                                :src="`https://cdn.cases.media/image/original/cases-landing-hero-dark-image-${locale}.png`"
                                width="666"
                                height="540"
                            />
                        </div>
                    </div>
                </div>

                <div class="hero-logos d-none d-lg-flex justify-content-between transition-up-3">
                    <div class="group">
                        <div class="fw-700 f-small tt-upper mb-1">{{ $t('hero.awards.grants') }}</div>
                        <div class="scheme-light-only">
                            <div class="items d-flex flex-nowrap">
                                <img class="group-item" src="/images/landing/grant-goethe.svg" width="108" height="72" alt="Goethe-Institut logo" loading="lazy">
                                <img class="group-item" src="/images/landing/grant-usf-2.svg" width="108" height="72" alt="Ukrainian Startup Fund logo" loading="lazy">
                                <img class="group-item" src="/images/landing/grant-7wings.svg" width="108" height="72" alt="7Wings logo" loading="lazy">
                                <img class="group-item" src="/images/landing/grant-ecf.svg" width="108" height="72" alt="european cultural foundation logo" loading="lazy">
                            </div>
                        </div>
                        <div class="scheme-dark-only">
                            <div class="items d-flex flex-nowrap">
                                <img class="group-item" src="/images/landing/grant-dark-goethe.svg" width="108" height="72" alt="Goethe-Institut logo" loading="lazy">
                                <img class="group-item" src="/images/landing/grant-dark-usf-2.svg" width="108" height="72" alt="Ukrainian Startup Fund logo" loading="lazy">
                                <img class="group-item" src="/images/landing/grant-dark-7wings.svg" width="108" height="72" alt="7Wings logo" loading="lazy">
                                <img class="group-item" src="/images/landing/grant-dark-ecf.svg" width="108" height="72" alt="european cultural foundation logo" loading="lazy">
                            </div>
                        </div>
                    </div>

                    <div class="group">
                        <div class="fw-700 f-small tt-upper mb-1">{{ $t('hero.awards.participant') }}</div>
                        <div class="scheme-light-only">
                            <div class="items d-flex flex-nowrap">
                                <img class="group-item" src="/images/landing/part-websummit.svg" width="108" height="72" alt="Websummit logo" loading="lazy">
                                <img class="group-item" src="/images/landing/part-omr.svg" width="108" height="72" alt="OMR logo" loading="lazy">
                                <img class="group-item" src="/images/landing/part-vivatech.svg" width="108" height="72" alt="VIVA Tech logo" loading="lazy">
                                <img class="group-item" src="/images/landing/grant-bett.svg" width="108" height="72" alt="bett" loading="lazy">
                            </div>
                        </div>
                        <div class="scheme-dark-only">
                            <div class="items d-flex flex-nowrap">
                                <img class="group-item" src="/images/landing/part-dark-websummit.svg" width="108" height="72" alt="Websummit logo" loading="lazy">
                                <img class="group-item" src="/images/landing/part-dark-omr.svg" width="108" height="72" alt="OMR logo" loading="lazy">
                                <img class="group-item" src="/images/landing/part-dark-vivatech.svg" width="108" height="72" alt="VIVA Tech logo" loading="lazy">
                                <img class="group-item" src="/images/landing/grant-dark-bett.svg" width="108" height="72" alt="bett" loading="lazy">
                            </div>
                        </div>
                    </div>

                    <div class="group">
                        <div class="fw-700 f-small tt-upper mb-1">{{ $t('hero.awards.winner') }}</div>
                        <div class="scheme-light-only">
                            <div class="items d-flex flex-nowrap">
                                <img class="group-item" src="/images/landing/win-cbcu.svg" width="108" height="72">
                            </div>
                        </div>
                        <div class="scheme-dark-only">
                            <div class="items d-flex flex-nowrap">
                                <img class="group-item" src="/images/landing/win-dark-cbcu.svg" width="108" height="72">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>


        <section class="block-about mt-5 mt-md-10 mt-lg-12 transition-up-4">
            <div class="content page-container">

                <div class="row">
                    <div class="col-12 col-lg-10 offset-lg-1 ta-center">

                        <h2 class="h4 h2-md mt-0 mb-2">{{ $t('about.title') }}</h2>

                        <div class="h3-md my-0 mb-n2">
                            <p v-html="$t('about.text', {
                                name: '<strong>CASES</strong>'
                            })"></p>
                        </div>

                    </div>
                </div>

            </div>

            <div class="image scheme-light-only mb-2 mb-md-10 mb-lg-0 mt-lg-2">
                <WebpPicture
                    class="d-block d-md-none"
                    imgClass="block-image about-image-mobile mx-auto"
                    :src="`https://cdn.cases.media/image/large/cases-landing-about-light-mobile-${locale}.png`"
                    width="768"
                    height="645"
                    loading="lazy"
                    alt=""
                />
                <WebpPicture
                    class="d-none d-md-block"
                    imgClass="block-image about-image mx-auto"
                    :src="`https://cdn.cases.media/image/original/cases-landing-about-light-${locale}.png`"
                    width="1648"
                    height="880"
                    loading="lazy"
                    alt=""
                />
            </div>

            <div class="image scheme-dark-only mb-2 mb-md-10 mb-lg-0 mt-lg-2">

                <WebpPicture
                    class="d-block d-md-none"
                    imgClass="block-image about-image-mobile mx-auto"
                    :src="`https://cdn.cases.media/image/large/cases-landing-about-dark-mobile-${locale}.png`"
                    width="768"
                    height="645"
                    loading="lazy"
                    alt=""
                />
                <WebpPicture
                    class="d-none d-md-block"
                    imgClass="block-image about-image mx-auto"
                    :src="`https://cdn.cases.media/image/original/cases-landing-about-dark-${locale}.png`"
                    width="1648"
                    height="880"
                    loading="lazy"
                    alt=""
                />

            </div>
        </section>


        <section class="mt-8 mt-md-10 mt-lg-12 transition-up-5">
            <div class="page-container">

                <div class="row">
                    <div class="col-12 col-lg-10 offset-lg-1 ta-center">

                        <h2 class="h4 h2-md mt-0 mb-2">{{ $t('features.title') }}</h2>

                        <p class="l-p my-0">
                            {{ $t('features.text') }}
                        </p>

                    </div>
                </div>

                <div class="features-section mt-2 mt-md-8 mt-lg-12">

                    <div class="demo-block d-none d-md-flex mr-2">
                        <transition name="fade-n-pop">

                            <section class="demo-media study-demo" v-if="activeFeatureIndex == 0" key="demo-0">

                                <div class="scheme-light-only">
                                    <WebpPicture
                                        class="d-block"
                                        :src="`https://cdn.cases.media/image/large/cases-landing-study-light-${locale}.png`"
                                        alt=""
                                        loading="lazy"
                                        width="1442"
                                        height="1408"
                                    />
                                </div>

                                <div class="scheme-dark-only">
                                    <WebpPicture
                                        class="d-block"
                                        :src="`https://cdn.cases.media/image/large/cases-landing-study-dark-${locale}.png`"
                                        alt=""
                                        loading="lazy"
                                        width="1442"
                                        height="1408"
                                    />
                                </div>

                            </section>

                            <section class="demo-media jobs-demo" v-if="activeFeatureIndex == 1" key="demo-1">
                                <div :key="`demo-1${videoColorMode}`">
                                    <video loop autoplay muted playsinline>
                                        <source :src="`/images/cases-landing/cases-landing-jobs${videoColorMode}-${locale}.webm`">
                                        <source :src="`/images/cases-landing/cases-landing-jobs${videoColorMode}-${locale}.mp4`">
                                    </video>
                                </div>
                            </section>

                            <section class="demo-media feed-demo" v-if="activeFeatureIndex == 2" key="demo-2">
                                <div :key="`demo-2${videoColorMode}`">
                                    <video loop autoplay muted playsinline>
                                        <source :src="`/images/cases-landing/cases-landing-animation-1${videoColorMode}-${locale}.webm`">
                                        <source :src="`/images/cases-landing/cases-landing-animation-1${videoColorMode}-${locale}.mp4`">
                                    </video>
                                </div>
                            </section>

                            <section class="demo-media posts-demo" v-if="activeFeatureIndex == 3" key="demo-3">
                                <div :key="`demo-3${videoColorMode}`">
                                    <video loop autoplay muted playsinline>
                                        <source :src="`/images/cases-landing/cases-landing-animation-2${videoColorMode}-${locale}.webm`">
                                        <source :src="`/images/cases-landing/cases-landing-animation-2${videoColorMode}-${locale}.mp4`">
                                    </video>
                                </div>
                            </section>

                            <section class="demo-media editor-demo" v-if="activeFeatureIndex == 4" key="demo-4">
                                <div :key="`demo-4${videoColorMode}`">
                                    <video  loop autoplay muted playsinline>
                                        <source :src="`/images/cases-landing/editor${videoColorMode}-${locale}.webm`">
                                        <source :src="`/images/cases-landing/editor${videoColorMode}-${locale}.mp4`">
                                    </video>
                                </div>
                            </section>

                        </transition>
                    </div>

                    <div class="copy-block">
                        <!-- Courses -->
                        <div class="feature mt-2 mt-md-0" id="study-feature">
                            <div class="mobile-feature-demo d-md-none mb-2 study-demo">
                                <WebpPicture
                                    class="d-block mx-auto"
                                    :src="`https://cdn.cases.media/image/large/cases-landing-study-mobile-${locale}.png`"
                                    alt=""
                                    loading="lazy"
                                    width="724"
                                    height="411"
                                />
                            </div>
                            <h3 class="l-h3 m-0">{{ $t('features.study.title') }}</h3>
                            <p class="mt-2">
                                {{ $t('features.study.text') }}
                            </p>

                            <nuxt-link
                                :to="localePath('/creativepractice/subscription')"
                                class="d-link fw-700"
                            >
                                {{ $t('features.study.link') }} <span class="icon-ic-arrow-right l-next" />
                            </nuxt-link>
                        </div>

                        <!-- Jobs -->
                        <div class="feature mt-2 mt-md-0" id="jobs-feature">
                            <div class="mobile-feature-demo d-md-none mb-2 jobs-demo-mobile">
                                <WebpPicture
                                    class="d-block mx-auto jobs-image"
                                    src="https://cdn.cases.media/image/standart/helper-cat-sent-2.png"
                                    alt=""
                                    loading="lazy"
                                    width="680"
                                    height="350"
                                />
                            </div>
                            <h3 class="l-h3 m-0">{{ $t('features.jobs.title') }}</h3>
                            <p class="mt-2">
                                {{ $t('features.jobs.text') }}
                            </p>

                            <nuxt-link
                                :to="localePath('/vacancies')"
                                class="d-link fw-700"
                            >
                                {{ $t('features.jobs.link') }} <span class="icon-ic-arrow-right l-next" />
                            </nuxt-link>
                        </div>

                        <!-- Feed -->
                        <div class="feature mt-8 mt-md-0" id="feed-feature">
                            <div class="mobile-feature-demo d-md-none mb-3 feed-demo">
                                <WebpPicture
                                    class="d-block mx-auto"
                                    :src="`https://cdn.cases.media/image/large/cases-landing-feed-mobile.png`"
                                    :imgClass="`block-image`"
                                    alt=""
                                    loading="lazy"
                                    width="680"
                                    height="441"
                                />
                            </div>
                            <h3 class="l-h3 m-0">{{ $t('features.feed.title') }}</h3>
                            <p class="mt-2">
                                {{ $t('features.feed.text') }}
                            </p>

                            <nuxt-link
                                :to="localePath('/registration?next=/my/feed')"
                                class="d-link fw-700"
                            >
                                {{ $t('features.feed.link') }} <span class="icon-ic-arrow-right l-next" />
                            </nuxt-link>
                        </div>

                        <!-- Posts -->
                        <div class="feature mt-8 mt-md-0" id="posts-feature">
                            <div class="mobile-feature-demo d-md-none mb-3 posts-demo">
                                <WebpPicture
                                    class="d-block mx-auto"
                                    :src="`https://cdn.cases.media/image/large/cases-landing-posts-mobile.png`"
                                    :imgClass="`block-image`"
                                    alt=""
                                    loading="lazy"
                                    width="672"
                                    height="353"
                                />
                            </div>
                            <h3 class="l-h3 m-0">{{ $t('features.cases.title') }}</h3>
                            <p class="mt-2">
                                {{ $t('features.cases.text') }}
                            </p>

                            <nuxt-link
                                :to="localePath('/registration?next=/my/portfolio')"
                                class="d-link fw-700"
                            >
                                {{ $t('features.cases.link') }} <span class="icon-ic-arrow-right l-next" />
                            </nuxt-link>
                        </div>

                        <!-- Editor -->
                        <div class="feature mt-8 mt-md-0" id="editor-feature">
                            <div class="mobile-feature-demo d-md-none mb-3 editor-demo">

                                <div class="scheme-light-only">
                                    <WebpPicture
                                        class="d-block mx-auto"
                                        imgClass="block-image"
                                        :src="`https://cdn.cases.media/image/large/cases-landing-editor-mobile-light.png`"
                                        width="672"
                                        height="225"
                                        loading="lazy"
                                        alt=""
                                    />
                                </div>

                                <div class="scheme-dark-only">
                                    <WebpPicture
                                        class="d-block mx-auto"
                                        imgClass="block-image"
                                        :src="`https://cdn.cases.media/image/large/cases-landing-editor-mobile-dark.png`"
                                        width="655"
                                        height="231"
                                        loading="lazy"
                                        alt=""
                                    />
                                </div>

                            </div>
                            <h3 class="l-h3 m-0">{{ $t('features.editor.title') }}</h3>
                            <p class="mt-2">
                                {{ $t('features.editor.text') }}
                            </p>

                            <nuxt-link
                                :to="localePath('/registration?next=/editor?type=case')"
                                class="d-link fw-700"
                            >
                                <span v-html="$t('features.editor.link')" /> <span class="icon-ic-arrow-right l-next" />
                            </nuxt-link>
                        </div>
                    </div>
                </div>

            </div>
        </section>


        <section class="mt-8 mt-md-10 mt-lg-12 transition-up-7">
            <div class="page-container">

                <div class="row">
                    <div class="col-12 col-lg-10 offset-lg-1 ta-center">

                        <h2 class="h4 h2-md mt-0 mb-2">{{ $t('talents.title') }}</h2>

                        <div class="h3-md my-0">
                            {{ $t('talents.text') }}
                        </div>

                    </div>
                </div>
            </div>

            <div class="talents-image-container mt-4 mt-lg-5">
                <div class="scheme-light-only">
                    <WebpPicture
                        class="d-block"
                        imgClass="block-image talents-image mx-auto"
                        src="https://cdn.cases.media/image/original/cases-landing-talents-light.png"
                        width="1394"
                        height="885"
                        loading="lazy"
                        alt=""
                    />
                </div>
                <div class="scheme-dark-only">
                    <WebpPicture
                        class="d-block"
                        imgClass="block-image talents-image mx-auto"
                        src="https://cdn.cases.media/image/original/cases-landing-talents-dark.png"
                        width="1394"
                        height="885"
                        loading="lazy"
                        alt=""
                    />
                </div>
            </div>
        </section>


        <section class="mt-8 mt-md-10 mt-lg-12 transition-up-8">
            <div class="page-container">

                <h2 class="h4 h2-md mt-0 mb-3 mb-md-4 ta-center">{{ $t('reviews.title') }}</h2>

            </div>

            <div class="sidescroll-compensate">
                <SideScroll class="d-flex flex-nowrap review-ribbon">

                    <Card
                        v-for="(review, i) in reviewContent"
                        :key="`reviews-item-${i}`"
                        class="review-item scroll-item py-5"
                    >

                        <template v-if="review.logo">
                            <nuxt-link
                                v-if="review.company"
                                :to="localePath(review.company)"
                                class="review-logo b-link"
                            >
                                <div :class="{
                                    'invert': !review.logoDark,
                                    'scheme-light-only': review.logoDark
                                }">
                                    <img :src="review.logo" width="258" height="50" loading="lazy" alt="">
                                </div>
                                <div v-if="review.logoDark" class="scheme-dark-only">
                                    <img :src="review.logoDark" width="258" height="50" loading="lazy" alt="">
                                </div>
                            </nuxt-link>

                            <div v-else class="review-logo">
                                <div :class="{
                                    'invert': !review.logoDark,
                                    'scheme-light-only': review.logoDark
                                }">
                                    <img :src="review.logo" width="258" height="50" loading="lazy" alt="">
                                </div>
                                <div v-if="review.logoDark" class="scheme-dark-only">
                                    <img :src="review.logoDark" width="258" height="50" loading="lazy" alt="">
                                </div>
                            </div>
                        </template>

                        <div class="review-text my-3 ta-center">
                            <p v-html="review.text"></p>
                        </div>

                        <nuxt-link
                            v-if="review.profile"
                            :to="localePath(review.profile)"
                            class="review-author d-link ta-center"
                        >
                            <div v-if="review.photo" class="review-author-photo mb-1">
                                <WebpPicture
                                    class="d-block mx-auto"
                                    :src="review.photo"
                                    :alt="review.name"
                                    loading="lazy"
                                    width="100"
                                    height="100"
                                />
                            </div>

                            <div class="review-author-name mb-h fw-700">{{ review.name }}</div>
                            <div class="review-author-position f-semismall" v-html="review.position" />
                        </nuxt-link>

                        <div v-else class="review-author ta-center">
                            <div v-if="review.photo" class="review-author-photo mb-1">
                                <WebpPicture
                                    class="d-block mx-auto"
                                    :src="review.photo"
                                    :alt="review.name"
                                    loading="lazy"
                                    width="100"
                                    height="100"
                                />
                            </div>

                            <div class="review-author-name mb-h fw-700">{{ review.name }}</div>
                            <div class="review-author-position f-semismall" v-html="review.position" />
                        </div>
                    </Card>

                </SideScroll>
            </div>

        </section>


        <!-- Register -->
        <section class="mt-8 mt-md-10 mt-lg-12 transition-up-9">
            <div class="page-container">
                <h2 class="h4 h2-md mt-0 mb-3 mb-md-4 mb-xl-8 ta-center">{{ $t('join.title') }}</h2>

                <div class="row">
                    <div class="col-md-6">
                        <Card class="join-card">
                            <div class="media pt-4 d-none d-md-block">
                                <div class="tape mb-2 users">
                                    <WebpPicture
                                        v-for="(pic, i) in usersTape1"
                                        :key="`pic-${i}-1u`"
                                        imgClass="rounded"
                                        alt=""
                                        :src="`https://cdn.cases.media/image/original/${pic}`"
                                        width="88"
                                        height="88"
                                        loading="lazy"
                                    />
                                   <WebpPicture
                                        v-for="(pic, i) in usersTape1"
                                        :key="`pic-${i}-2u`"
                                        imgClass="rounded"
                                        alt=""
                                        :src="`https://cdn.cases.media/image/original/${pic}`"
                                        width="88"
                                        height="88"
                                        loading="lazy"
                                    />
                                </div>
                                <div class="tape users right">
                                    <WebpPicture
                                        v-for="(pic, i) in usersTape2"
                                        :key="`pic-${i}-3u`"
                                        imgClass="rounded"
                                        alt=""
                                        :src="`https://cdn.cases.media/image/original/${pic}`"
                                        width="88"
                                        height="88"
                                        loading="lazy"
                                    />
                                    <WebpPicture
                                        v-for="(pic, i) in usersTape2"
                                        :key="`pic-${i}-4u`"
                                        imgClass="rounded"
                                        alt=""
                                        :src="`https://cdn.cases.media/image/original/${pic}`"
                                        width="88"
                                        height="88"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                            <div class="p-4 d-flex flex-column h-100">
                                <h3 class="f-24 mb-2 fw-800 mt-0">
                                    {{ $t('join.user.title') }}
                                    <nuxt-link :to="localePath('/registration')" class="b-link">{{ $t('join.user.titleAction') }}</nuxt-link>
                                </h3>
                                <p>
                                    {{ $t('join.user.text1') }} <br>
                                    {{ $t('join.user.text2') }}
                                </p>
                                <nuxt-link
                                    class="d-link fw-800 f-19 mt-auto"
                                    :to="localePath('/registration')"
                                >
                                    {{ $t('join.user.link') }} <span class="icon-ic-arrow-right l-next" />
                                </nuxt-link>
                            </div>
                        </Card>
                    </div>
                    <div class="col-md-6 mt-2 mt-md-0">
                        <Card class="join-card">
                            <div class="media pt-4 d-none d-md-block">
                                <div class="tape mb-2">
                                    <WebpPicture
                                        v-for="(pic, i) in companiesTape1"
                                        :key="`pic-${i}-1`"
                                        imgClass="polished"
                                        alt=""
                                        :src="`https://cdn.cases.media/image/original/${pic}`"
                                        width="88"
                                        height="88"
                                        loading="lazy"
                                    />
                                    <WebpPicture
                                        v-for="(pic, i) in companiesTape1"
                                        :key="`pic-${i}-2`"
                                        imgClass="polished"
                                        alt=""
                                        :src="`https://cdn.cases.media/image/original/${pic}`"
                                        width="88"
                                        height="88"
                                        loading="lazy"
                                    />
                                </div>
                                <div class="tape right">
                                    <WebpPicture
                                        v-for="(pic, i) in companiesTape2"
                                        :key="`pic-${i}-3`"
                                        imgClass="polished"
                                        alt=""
                                        :src="`https://cdn.cases.media/image/original/${pic}`"
                                        width="88"
                                        height="88"
                                        loading="lazy"
                                    />
                                    <WebpPicture
                                        v-for="(pic, i) in companiesTape2"
                                        :key="`pic-${i}-4`"
                                        imgClass="polished"
                                        alt=""
                                        :src="`https://cdn.cases.media/image/original/${pic}`"
                                        width="88"
                                        height="88"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                            <div class="p-4 d-flex flex-column h-100">
                                <h3 class="f-24 mb-2 fw-800 mt-0">
                                    {{ $t('join.company.title') }}
                                    <nuxt-link :to="localePath('/registration?next=/my/companies')" class="b-link">{{ $t('join.company.titleAction') }}</nuxt-link>
                                </h3>
                                <p>
                                    {{ $t('join.company.text') }}
                                </p>
                                <nuxt-link
                                    class="d-link fw-800 f-19 mt-auto"
                                    :to="localePath('/registration?next=/my/companies')"
                                >
                                    {{ $t('join.company.link') }} <span class="icon-ic-arrow-right l-next" />
                                </nuxt-link>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </section>


        <section class="mt-8 mt-md-10 mt-lg-12 transition-up-10">
            <div class="page-container">

                <Faq
                    class="faq-wrapper"
                    :title="$t('faq.title')"
                    :titleClass="`h4 h2-md mt-0 mb-4 ta-center`"
                    :pairs="faqContent"
                    :locale="locale"
                />

            </div>
        </section>

    </div>
</template>

<script>
import { useAppStore } from '../stores/app';
    export default {
        scrollToTop: true,

        name: "casesLanding",

        layout: 'cases',

        components: {
            Card: () => import('~/components/cards/Card.vue'),
            Faq: () => import('~/components/Faq.vue'),
            SideScroll: () => import('~/components/SideScroll.vue'),
            WebpPicture: () => import('~/components/WebpPicture.vue')
        },

        head () {
            const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

            let link = [
                { rel: "preload", href: `https://cdn.cases.media/image/large/cases-landing-hero-background.png.webp`, as: "image" },
                { rel: "preload", href: `https://cdn.cases.media/image/original/cases-landing-hero-image-tablet-${this.locale}.png.webp`, as: "image" },
                { rel: "preload", href: `https://cdn.cases.media/image/original/cases-landing-hero-image-${this.locale}.png.webp`, as: "image" },
                { rel: "preload", href: `https://cdn.cases.media/image/large/cases-landing-hero-dark-gradient.png.webp`, as: "image" },
                { rel: "preload", href: `https://cdn.cases.media/image/original/cases-landing-hero-dark-image-tablet-${this.locale}.png.webp`, as: "image" },
                { rel: "preload", href: `https://cdn.cases.media/image/original/cases-landing-hero-dark-image-${this.locale}.png.webp`, as: "image" },
                ...i18nHead.link
            ];

            /** @see: https://developers.google.com/search/docs/appearance/site-names#json-ld_1 */
            let structuredData = {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "name": "CASES",
                "alternateName": ["CASES Media", "Cases", "Соцмережа та EdTech-платформа"],
                "url": "https://cases.media/"
            }


            return {
                title: this.$t('head.cases.title'),

                meta: [
                    { hid: 'title',       name: "title",       content: this.$t('head.cases.title') },
                    { hid: 'description', name: 'description', content: this.$t('cases.description') },

                    { hid: "og:url",         property: "og:url",         content: `${process.env.baseUrl}/cases`},
                    { hid: "og:title",       property: "og:title",       content: this.$t('head.cases.title')},
                    { hid: "og:description", property: "og:description", content: this.$t('cases.description')},
                    { hid: "og:image",       property: "og:image",       content: `${process.env.baseUrl}/images/CASES-OG.png`},

                    { hid: "twitter:url",         name: "twitter:url"  ,       content: `${process.env.baseUrl}/cases`},
                    { hid: "twitter:title",       name: "twitter:title",       content: this.$t('head.cases.title')},
                    { hid: "twitter:description", name: "twitter:description", content: this.$t('cases.description')},
                    { hid: "twitter:image",       name: "twitter:image",       content: `${process.env.baseUrl}/images/CASES-OG.png`}
                ],

                link,

                script: [
                    {
                        hid: "ldWebSite",
                        type: 'application/ld+json',
                        json: structuredData
                    }
                ]
            };
        },

        async asyncData ({ $auth, app, redirect, route }) {
            if ($auth.user && !route.query.noredirect) {
                return redirect(app.localePath('/my/feed'));
            }
        },

        data () {
            return {
                faqContent: [
                    {
                        question: this.$t('faq.pair1.q'),
                        answer:   this.$t('faq.pair1.a')
                    },
                    {
                        question: this.$t('faq.pair2.q'),
                        answer:   this.$t('faq.pair2.a')
                    },
                    {
                        question: this.$t('faq.pair3.q'),
                        answer:   this.$t('faq.pair3.a')
                    },
                    {
                        question: this.$t('faq.pair4.q'),
                        answer:   this.$t('faq.pair4.a')
                    },
                    {
                        question: this.$t('faq.pair5.q'),
                        answer:   this.$t('faq.pair5.a')
                    },
                    {
                        question: this.$t('faq.pair6.q'),
                        answer:   this.$t('faq.pair6.a')
                    },
                    {
                        question: this.$t('faq.pair7.q'),
                        answer:   this.$t('faq.pair7.a')
                    }
                ],

                reviewContent: [
                    {
                        name:     this.$t('reviews.item1.name'),
                        position: this.$t('reviews.item1.position'),
                        text:     this.$t('reviews.item1.text'),
                        photo:    'https://cdn.cases.media/image/original/cases-landing-review-samovarov.png',
                        profile:  '/mike_samovarov',
                        logo:     '/images/landing/League.svg',
                        company:  '/league-design-agency'
                    },
                    {
                        name:     this.$t('reviews.item2.name'),
                        position: this.$t('reviews.item2.position'),
                        text:     this.$t('reviews.item2.text'),
                        photo:    'https://cdn.cases.media/image/original/cases-landing-review-herashchenko.png',
                        profile:  '/roman_gerashchenko',
                        logo:     '/images/landing/Sasquatch.svg',
                        logoDark: '/images/landing/Sasquatch-dark.svg',
                        company:  '/sasquatch.agency'
                    },
                    {
                        name:     this.$t('reviews.item3.name'),
                        position: this.$t('reviews.item3.position'),
                        text:     this.$t('reviews.item3.text'),
                        photo:    'https://cdn.cases.media/image/original/cases-landing-review-yakubovych.png',
                        profile:  '/max_yakubovych',
                        logo:     '/images/landing/Goodface.svg',
                        logoDark: '/images/landing/Goodface-dark.svg',
                        company:  '/goodface'
                    },
                    {
                        name:     this.$t('reviews.item5.name'),
                        position: this.$t('reviews.item5.position'),
                        text:     this.$t('reviews.item5.text'),
                        photo:    'https://cdn.cases.media/image/original/cases-landing-review-kravchuk.png',
                        profile:  '/julia_kravchuk',
                        logo:     '/images/landing/Reface.svg',
                        company:  '/reface'
                    },
                    {
                        name:     this.$t('reviews.item4.name'),
                        position: this.$t('reviews.item4.position'),
                        text:     this.$t('reviews.item4.text'),
                        photo:    'https://cdn.cases.media/image/original/cases-landing-review-shevchenko.png',
                        profile:  '/olga_shevchenko',
                        logo:     '/images/landing/Vintage.svg',
                        company:  '/vintage_web_production'
                    },
                    {
                        name:     this.$t('reviews.item6.name'),
                        position: this.$t('reviews.item6.position'),
                        text:     this.$t('reviews.item6.text'),
                        photo:    'https://cdn.cases.media/image/original/cases-landing-review-beseda.png',
                        profile:  '/artem_beseda',
                        logo:     '/images/landing/Rocketman.svg',
                        company:  '/rocketmen'
                    }
                ],

                features: [
                    {name: 'study-feature', pos: 0},
                    {name: 'jobs-feature', pos: 0},
                    {name: 'feed-feature', pos: 0},
                    {name: 'posts-feature', pos: 0},
                    {name: 'editor-feature', pos: 0},
                ],
                lastKnownScrollPosition: 0,
                activeFeatureIndex: 0,
                ticking: false,
                offset: 0,

                usersTape1: [
                    'cases-landing-user-1.png',
                    'cases-landing-user-2.png',
                    'cases-landing-user-3.png',
                    'cases-landing-user-4.png',
                    'cases-landing-user-5.png',
                    'cases-landing-user-6.png'
                ],
                usersTape2: [
                    'cases-landing-user-7a.png',
                    'cases-landing-user-8.png',
                    'cases-landing-user-9.png',
                    'cases-landing-user-10.png',
                    'cases-landing-user-11.png',
                    'cases-landing-user-12a.png'
                ],
                companiesTape1: [
                    'cases-landing-company-1.png',
                    'cases-landing-company-2.png',
                    'cases-landing-company-3.png',
                    'cases-landing-company-4.png',
                    'cases-landing-company-5.png',
                    'cases-landing-company-6.png'
                ],
                companiesTape2: [
                    'cases-landing-company-7.png',
                    'cases-landing-company-8.png',
                    'cases-landing-company-9.png',
                    'cases-landing-company-10.png',
                    'cases-landing-company-11.png',
                    'cases-landing-company-12.png'
                ],
            };
        },

        computed: {
            locale () {
                return this.$i18n && this.$i18n.locale || 'uk';
            },

            appStore () {
                return useAppStore();
            },

            videoColorMode () {
                return this.appStore.colorMode == 'dark' ? '-dark' : ''
            }
        },

        mounted () {
            if (process.browser) {
                this.$fbPixel.trackSingle('ViewContent');
            }

            // document.body.setAttribute('data-theme', 'light');
            document.body.classList.add('homepage-body');

            this.updatePositions();
            this.getActiveFeature();
            window.addEventListener('resize', this.updatePositions);
            document.addEventListener("scroll", this.getActiveFeature);
        },

        methods: {
            updatePositions () {
                const elements = document.querySelectorAll('.feature');
                elements.forEach(el => {
                    let rect = el.getBoundingClientRect();

                    let feature = this.features.find(o => o.name == el.id);
                    feature.pos = rect.bottom + window.scrollY;
                });

                this.offset = window.innerHeight / 2 + 57;
            },

            getActiveFeature () {
                this.lastKnownScrollPosition = window.scrollY;

                if (this.ticking) return;

                this.ticking = true;

                window.requestAnimationFrame(() => {
                    this.updatePositions();
                    
                    // Get first feature that has lowest position
                    // And greater than scroll position
                    let index = -1;

                    this.features.forEach((o, i) => {
                        if (o.pos > (this.lastKnownScrollPosition + this.offset)) {
                            if (index < 0 || this.features[index].pos > o.pos) {
                                index = i;
                            }
                        }
                    });
                    if (index < 0) index = this.features.length -1;
                    this.activeFeatureIndex = index;

                    this.ticking = false;
                });                
            },

            showLoginDialog () {
                this.$modalityStore.openModal({
                    type: 'drawer',
                    name: 'login'
                })
            },
        },

        destroyed () {
            // window.removeEventListener('keydown', this.shortCuts);
            // this.unobserve();

            // document.body.removeAttribute('data-theme');
            document.body.classList.remove('homepage-body');

            window.removeEventListener('resize', this.updatePositions);
            document.removeEventListener("scroll", this.getActiveFeature);
        }
    };
</script>


<style lang="postcss" scoped>
    .block-hero {
        position: relative;

        @media screen and (min-width: 992px) {
            overflow: hidden;

            height:     calc(100vh - 57px);
            min-height: 646px;
            max-height: 800px;
        }

        > .page-container {
            @media screen and (min-width: 992px) {
                height: 100%;

                display: flex;
                flex-flow: column nowrap;
                align-items: stretch;
                justify-content: space-between;
            }
        }
    }

    .hero-main {
        @media screen and (min-width: 992px) {
            flex: 1 0 auto;
        }
    }

    .hero-stencil-logo {
        display: inline-block;

        background-color: #000;
        background-image: linear-gradient(
            90deg,
            #815BA6 0%,
            #EB4984 12.5%,
            #F56B61 25%,
            #FA8C40 37.5%,
            #FDC50B 50%,
            #FA8C40 67.5%,
            #F56B61 75%,
            #EB4984 87.5%,
            #815BA6 100%
        );
        background-size: 400% 100%;

        animation: hero-stencil-logo-bg 40s linear infinite;

        svg {
            margin: -1px;

            display:   block;
            max-width: calc(100% + 2px);
            width:     222px;
            height:    auto;

            @media screen and (min-width: 768px) {
                margin: -2px;

                max-width: calc(100% + 4px);
                width:     322px;
            }
        }
    }

    @keyframes hero-stencil-logo-bg {
        to {
            background-position: 400% center;
        }
    }

    .hero-actions {
        .action-video {
            .icon {
                margin: -4px -12px -4px 12px;

                display: block;
                width:   24px;
                height:  24px;

                @media screen and (min-width: 768px) {
                    margin: -12px -20px -12px 12px;

                    width:  40px;
                    height: 40px;
                }
            }
        }
    }

    .hero-actions > *,
    .action-createcompany {
        @media screen and (min-width: 768px) {
            padding: 16px 24px;
        }
    }

    .hero-scroll {
        font-size:   12px;
        line-height: 1.333333;

        padding: 4px 0;

        > .icon-ic-mice {
            font-size: 18px;

            margin: -1px 0;
            margin-right: 6px;

            display: block;
        }
    }

    .hero-image {
        overflow: visible;

        .bg-glow {
            transform: translateY(-50%);

            width:  810px;
            height: auto;

            position: absolute;
            left:     -128px;
            top:      50%;
        }

        .bg {
            width:  630px;
            height: auto;
        }

        .bg-glow,
        .bg,
        .fg {
            display:   block;
            max-width: none;
        }

        .fg {
            width:   666px;
            height:  auto;

            position: absolute;
            top:      56px;
            left:     65px;

            @media screen and (min-width: 1280px) {
                left: 48px;
            }
        }

        .fg-card {
            display: none;

            @media screen and (min-height: 794px) {
                display: block;
            }
        }

        .fg-nocard {
            @media screen and (min-height: 794px) {
                display: none;
            }
        }
    }

    .icon-anim-scroll {
        margin: -1px 0;
        border: 2px solid currentColor;
        border-radius: 9000px;

        display: block;
        width:   12px;
        height:  18px;

        position: relative;

        &::before {
            content: '';

            animation:  icon-anim-scroll 1.6s ease infinite;
            background: currentColor;

            border-radius: 50%;

            display: block;
            width:   2px;
            height:  2px;

            position: absolute;
            top:      2px;
            left:     3px;
        }
    }

    @keyframes icon-anim-scroll {
        to {
            opacity:   0;
            transform: translateY(5px);
        }
    }

    .hero-logos {
        .items {
            margin: -2px;
        }

        .group-item {
            margin: 2px;

            width: 92px;
            flex:  0 0 92px;

            @media screen and (min-width: 1200px) {
                width: 108px;
                flex:  0 0 108px;
            }
        }
    }

    :deep(.block-image) {
        display:   block;
        max-width: 100%;
        height:    auto;
    }

    .block-about {
        display:     flex;
        flex-flow:   column nowrap;
        align-items: stretch;

        .content {
            order: 2;

            @media screen and (min-width: 992px) {
                order: -1;
            }
        }
    }

    :deep(.about-image) {
        max-height:      880px;
        object-fit:      cover;
        object-position: 50% 50%;
        aspect-ratio:    10 / 8;
        margin:          -96px 0 -128px;
    }

    :deep(.about-image-mobile) {
        max-height:      645px;
        object-fit:      cover;
        object-position: 50% 50%;
        aspect-ratio:    8 / 15;
        margin:          -150px 0 -90px;
    }

    .l-h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;

        @media screen and (min-width: 768px) {
            font-size: 24px;
            line-height: 28px;
        }

        @media screen and (min-width: 1280px) {
            font-size: 28px;
            line-height: 36px;
        }
    }


    .features-section {
        position: relative;
        display: flex;
    }

    .sidescroll-compensate {
        margin: -20px 0;
    }

    .review-ribbon {
        :deep(.icon-ic-arrow-simple) {
            top: 50%;
        }
    }

    .review-item {
        white-space: normal;

        margin: 0 12px;

        display:         flex;
        flex-flow:       column nowrap;
        justify-content: space-between;
        align-items:     stretch;
        flex:            1 0 calc(100% - 56px);
        min-width:       288px;
        max-width:       336px;

        @media screen and (min-width: 992px) {
            flex-basis: 300px;
        }

        @media screen and (min-width: 1200px) {
            flex-basis: 328px;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .review-logo {
        display: block;

        img {
            display: block;
            width:   100%;
            height:  56px;

            object-fit:      scale-down;
            object-position: 50% 50%;
        }

        .invert img {
            @media (prefers-color-scheme: dark) {
                filter: invert(1);
            }

            [data-theme='light'] & {
                filter: none;
            }

            [data-theme='dark'] & {
                filter: invert(1);
            }
        }
    }

    .review-text {
        p:last-child {
            margin-bottom: 0;
        }
    }

    .review-author {
        display: block;
    }


    :deep(.review-author-photo img) {
        border-radius: 50%;
        margin:        0 auto;

        display: block;
        width:   64px;
        height:  64px;

        object-fit:      cover;
        object-position: 50% 0;

        @media screen and (min-width: 992px) {
            width:  100px;
            height: 100px;
        }
    }


    .review-author-position {
        min-height: 32px;
    }

    .copy-block {
        width: 100%;
        flex-grow: 0;

        @media screen and (min-width: 768px) {
            max-width: 460px;
        }
    }

    .demo-block {
        width: 100%;
        height: 100vh;
        max-height: 500px;

        display: flex;
        align-items: center;
        position: sticky;
        top: calc(50% - 250px);

        will-change: transform;
    }

    .demo-media {
        max-width: 464px;
        position: absolute;

        :deep(img),
        video {
            max-width: 100%;
            width: 100%;
            height: auto;
        }

        &.study-demo {
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .study-demo {
        width: 100%;

        :deep(img) {
            height: auto;
        }
    }

    .jobs-demo {
        max-width: 464px;
    }

    .jobs-demo-mobile {
        :deep(img) {
            border-radius: 8px;
            height: auto;
        }
    }

    .demo-media.study-demo {
        :deep(img) {
            margin:    -100px;
            max-width: calc(100% + 200px);
            width:     auto;
            height:    auto;

            @media screen and (min-width: 992px) {
                margin:    -140px;
                max-width: calc(100% + 280px);
            }
        }
    }

    .editor-demo {
        max-width: 415px;
    }


    .feed-demo {
        max-width: 592px;
    }

    .mobile-feature-demo {
        margin: 0 auto;

        max-width: 490px;

        img,
        video {
            max-width: 100%;
            width: 100%;
        }
    }

    .feature {
        position: relative;
        width: 100%;

        @media screen and (min-width: 768px) {
            height: 100vh;
            max-height: 500px;

            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .l-p {
        font-size: 16px;
        line-height: 24px;

        @media screen and (min-width: 768px) {
            font-size: 19px;
            line-height: 24px;
        }

        @media screen and (min-width: 1280px) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    .l-strong {
        font-size: 18px;
        line-height: 24px;

        @media screen and (min-width: 768px) {
            font-size: 19px;
            line-height: 24px;
        }

        @media screen and (min-width: 1280px) {
            font-size: 20px;
            line-height: 140%;
        }
    }

    .f-24 {
        font-size: 18px;
        line-height: 24px;

        @media screen and (min-width: 768px) {
            font-size: 21px;
            line-height: 24px;
        }

        @media screen and (min-width: 992px) {
            font-size:  24px;
            line-height: 28px;
        }
    }

    .f-19 {
        font-size: 16px;
        line-height: 20px;

        @media screen and (min-width: 992px) {
            font-size:  19px;
            line-height: 24px;
        }
    }

    .join-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: 16px;
        padding: 0;
        overflow: hidden;

        &:hover {
            .tape {
                animation-play-state: running;
            }
        }
    }

    .l-next {
        font-size: 14px;
        width: 24px;
        height: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .talents-image-container {
        overflow: visible;
    }

    :deep(.talents-image) {
        margin: -192px 0 -224px;

        min-height: 875px;
        max-height: 875px;

        object-fit: cover;
        object-position: 50% 50%;
        aspect-ratio:    9 / 8;

        @media screen and (min-width: 768px) {
            margin: -168px 0 -192px;
        }

        @media screen and (min-width: 992px) {
            margin: -96px 0 -144px;
        }
    }

    .block-company {
        position: relative;

        .bg-vid {
            position: absolute;
            top:      0;
            right:    0;
            bottom:   0;
            left:     0;
            z-index:  -1;
            overflow: hidden;

            min-height: 320px;
            max-height: 720px;

            > video {
                object-fit: cover;
                object-position: 50% 50%;
                width:      100%;
                height:     100%;
            }
        }

        .content {
            padding: 112px 0;

            @media screen and (min-width: 768px) {
                padding: 176px 0;
            }

            @media screen and (min-width: 992px) {
                padding: 256px 0;
            }
        }
    }


    .tape {
        animation-duration: 8s;
        animation-name:            infinite-scroll-left;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-play-state: paused;

        display: flex;
        align-items: center;

        width: 480px;
        will-change: transform;

        &.right {
            animation-name: infinite-scroll-right;
            transform: translate3d(-100%, 0, 0);
        }

        picture {
            flex-shrink: 0;
            margin-left: 16px;
        }

        :deep(img) {
            display: block;
            width: 64px;
            height: 64px;

            box-shadow: 0px 1px 1px var(--color-cases-secondary-button-shadow-2),
                        0px 1px 4px var(--color-cases-secondary-button-shadow-1);
        }


        @media screen and (min-width: 992px) {
            width: 636px;

            picture {
                margin-left: 18px;
            }

            :deep(img) {
                width: 88px;
                height: 88px;
            }
        }

        :deep(.rounded) {
            overflow: hidden;
            border-radius: 50%;
        }

        :deep(.polished) {
            overflow: hiddden;
            border-radius: 16px;
        }
    }


    @keyframes infinite-scroll-left {
        100% {
            transform: translate3d(-100%, 0, 0);
        }
    }

    @keyframes infinite-scroll-right {
        100% {
            transform: translate3d(0%, 0, 0);
        }
    }

    .faq-wrapper {
        :deep(.collapse-panel-plain .collapse-inner) {
            padding-top: 8px;
        }

        :deep(.collapse-header) {
            @media screen and (min-width: 768px) {
                font-size:   19px;
                line-height: 1.263158;
            }
        }
    }
</style>
